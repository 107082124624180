import Vue from 'vue/dist/vue.esm'
import TurbolinksAdapter from 'vue-turbolinks'
import AxiosRequest from "../utils/axios-request"

# FILTERS
import toCurrency from '../filters/to_currency'

# METHODS
import CancelDelivery from '../methods/deliveries/cancel_delivery'

Vue.use TurbolinksAdapter

document.addEventListener 'turbolinks:load', ->
  el = document.getElementById 'show_delivery'
  return false unless el?
  new Vue {
    el
    filters: {toCurrency}
    data: -> {
      products: []
      orde_delivery: []
    }
    methods: {
      CancelDelivery
    }
    mounted: ->
      id = document.getElementById('id').dataset.id
      uid = document.getElementById('uid').dataset.uid
      query = $.get "/customers/#{uid}/deliveries/#{id}.json"
      query.done (res) =>
        @products = res.products
        @orde_delivery = res.orde_delivery
    computed: {
      subtotal: ->
        {products} = @
        return 0 if Object.keys(products).length == 0
        acum = 0
        for item in @products
          if item.subtotal > 0
            acum += parseInt item.subtotal
        acum
      discount: ->
        {products} = @
        return 0 if Object.keys(products).length == 0
        acum = 0
        for item in @products
          if item.dicount > 0
            acum += parseInt item.dicount
        acum
      iva: ->
        {products} = @
        return 0 if Object.keys(products).length == 0
        acum = 0
        for item in @products
          if item.paid_tax > 0
            acum += parseInt item.paid_tax
        acum
      total: ->
        {products} = @
        return 0 if Object.keys(products).length == 0
        acum = 0
        for item in @products
          if item.paid_price > 0
            acum += parseInt item.paid_price
        acum
      delivery: ->
        {products} = @
        return 0 if Object.keys(products).length == 0
        acum = 0
        for item in @products
          if item.shipping > 0
            acum += parseInt item.shipping
        acum
      total_with_delivery: ->
        {products} = @
        return 0 if Object.keys(products).length == 0
        acum = 0
        for item in @products
          if item.paid_price > 0
            acum += parseInt item.paid_price + item.shipping
        acum
    }
  }
